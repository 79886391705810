.startup-container{
    display: flex;
}

.startup-header-info-container{
    border-color: black;
    border-width: 2px;
    padding:'15px';
}

.startup-info-form-container{
    min-height: 300px;
    background-color: #eee;
    display: flex;
    
}

.startup-form-field{
    width:100%;
    box-shadow:1px 1px 2px 2px #ddd;
}