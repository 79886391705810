.item-detail-panel-container {
    background-color: white;
    flex:1;
}

.item-detail-panel-header {
    background-color: #fff;
    box-shadow:2px 2px 10px #cfcfcf;
    padding:20px;
    width:100%;

}

.item-detail-panel-header p {
    color:#6f6f6f;
    margin:0px;
    font-size: 16px;
    font-weight: 400;
    align-self:flex-start;
}

.item-detail-panel-header h1 {
    color:#6f6f6f;
    margin:0px;
    font-size: 20px;
    font-weight: 400;
    align-self:flex-start;
}