.tab-header {
    font-size:20px;
}

.tab-container {
    background-color: transparent;
}

.tab-container>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
    padding:0px;
}


.system-tree-container{
    flex:1;
    background-color: transparent;
}

.system-tabs-container{
    flex:7;
    background-color: transparent;
    /* box-shadow: 1px 1px 20px #cfcfcf; */
    margin-right: 30px;
    height:1100px;
}

.system-tabs-container span {
    font-size: 16px;
    display:flex;
    align-items:center;
}

/* .system-tabs-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color:red;
}

.system-tabs-container .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 2px solid red;
    z-index: 2;
} */