.panel-container {
    display:flex;
    padding:20px 50px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #bfbfbf;
    background-color: white;
}

.avatar-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width:100%;
}

.avatar-container p {
    margin:0px;
    font-size:16px;
    color:#8a8a8a;
}

.avatar-container h2 {
    font-size: 22px;
    font-weight: 500;
    color:#6f6f6f;
}

.avatar-stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right:60px;
}

.progress-container {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding:0px 0px;
    width: 500px;
}

.progress-container h1 {
    font-size:28px;
    font-weight:400;
    color:#323232;
    align-self:flex-start;
}

.info-container {
    display:flex;
    flex:1;
    /* margin-left: 40px; */
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* border-radius: 10px; */
    /* box-shadow: 1px 1px 10px #6f6f6f; */
}

.job-header-container {
    margin:0px;
    padding-bottom:30px;
    align-self: flex-start;
}

.job-header-container h2 {
    font-size:24px;
    font-weight: 400;
    color:#6f6f6f;
    margin:0px;
    line-height: 1;
}

.job-header-container p {
    font-size:18px;
    color:#6f6f6f;
    margin: 10px 0px 0px 0px;
    line-height: 1;
}

.card-header {
    color:#6f6f6f;
    font-size: 20px;
    font-weight: 400;
    align-self:flex-start;
}

.divider {
    border-left:2px solid #dfdfdf;
    height:90%;
    align-self: center;
}