@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  height:'100%';
  font-family: -apple-system, BlinkMacSystemFont,'Lato', 'Raleway', 'Montserrat Alternates','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --shade0: #fff;
  --shade100: #000;
  
  --neutral50: #f8fafc;
  --neutral100: #f1f5f9;
  --neutral200: #e2e8fd;
  --neutral300: #cbd5e1;
  --neutral400: #9aa3b8;
  --neutral500: #647489;
  --neutral600: #475569;
  --neutral700: #334155;
  --neutral800: #1e293b;
  --neutral900: #0f172a;

  --primary50: #eff6ff;
  --primary300: #93c5fd;
  --primary500: #3b82f6;
  --primary700: #1d4ed8;
  --primary900: #0a2683;

  --error50: #fef2f2;
  --error300: #ff949b;
  --error500: #ef4444;
  --error700: #b91c1c;
  --error900: #7f1d1d;
}

/* DESIGN ELEMENTS */
.marg-top-s{
  margin-top:5px;
}

.marg-top-m{
  margin-top:15px;
}

.marg-top-l{
  margin-top:25px;
}

.marg-left-s{
  margin-left:5px;
}

.marg-left-m{
  margin-left:15px;
}

.marg-left-l{
  margin-left:25px;
}


.errmsg{
  color:var(--error500);
  font-size: 16px;
  font-style: italic;
}

.stack-container{
  display:'flex';
  flex-direction: 'column';
}

.h-fill{
  width:100%;
}

.v-fill{
  height:100%;
}




.login-button{
  background-color: var(--primary500);
  color: var(--shade0);
  font-size: 18px;
  padding: 8px 8px 10px 8px;
  border-radius: 5px;
  border-width: 0px;
  transition: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 12px;
}

.login-button:hover{
  opacity: 0.90;
}

.login-button:focus{
  outline:none;
}


.signup-button{
  background-color: var(--primary500);
  color: var(--shade0);
  font-size: 14px;
  padding: 7px 7px 9px 7px;
  border-radius: 5px;
  border-width: 0px;
  transition: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 12px;
}

.signup-button:hover{
  opacity: 0.90;
}

.signup-button:focus{
  outline:none;
}


.cancel-button{
  background-color: var(--error500);
  color: var(--shade0);
  font-size: 18px;
  padding: 8px 8px 10px 8px;
  border-radius: 5px;
  border-width: 0px;
  transition: 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 12px;
}

.cancel-button:hover{
  opacity: 0.90;
}

.cancel-button:focus{
  outline:none;
}

.colored-panel {
  background-image: linear-gradient(to right, #1d4ed8, #3b82f6);
  display: flex;
  flex:0.85;
  flex-direction: column;
  align-items: center;
}

.login-field-container{
  width:100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 12px;
  margin:0px 0px 10px 0px;
}

.login-form-container{
  min-width: 30%;
}

.login-field {
  font-size: 16pt;
  border: 1px solid var(--neutral300);
  color: var(--neutral500);
  border-radius: 5px;
  width:100%;
  outline:none;
  padding: 5px 10px;
  box-sizing: border-box;
  transition: 0.5s;
}

.login-field:focus{
  border: 1px solid var(--neutral400);
}

.login-field:active{
  
}

.login-field-label{
  font-size: 14pt;
  color: var(--neutral500);
}