.supply-grd {
    background-color: #fff;
}

.return-grd{
    background-color: #fff;
}

.transfer-grd{
    background-color: lightsteelblue;
}

.service-type-block{
    text-align: center;
}

.service-type-block span{
    font-size: '40px';
}

.air-balance-totals-panel{
    display:flex;
    padding:10px;
}

.air-balance-totals{
    flex:1;
    padding:20px;
    box-shadow: 2px 2px 20px #ccc;
    border-radius: 8px;
    margin:0px 10px;
}
